
import { defineComponent } from 'vue';
import { Button, IconButton, Card, DataList } from '@/ui/index';
import { MachineController } from '@/controller/';
import { Machine } from '@/model/index.ts';
import store from "@/store/index";

export default defineComponent({
    name: "MachineDetail",
    components: { Button, IconButton, Card, DataList },
    data(){
        return {
            store,
            machine: {} as Machine,
            workareas: [] as Array<any>,
            workareaItemSettings: [
                { property: "number", dense: true, type: "text", formPlaceHolder: "0"},
                { property: "title", type: "text", fieldGrow: true, formPlaceHolder:"Neuer Arbeitsbereich"}
            ]
        }
    },
    created() {
        if (this.$route.params.id) {
            this.handleGetMachine(this.$route.params.id);
        }
    },
    
    methods: {
        handleGoBack() {
            this.$router.go(-1);
        },
        handleEdit() {
            this.$router.push("/administration/machine/form/" + this.machine.uid);
        },
        handleConfirmDeleteMachine() {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteMachineText', { name: this.machine.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete() },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleGetMachine(uid:any) {
            const res = await MachineController.getMachineById(uid);
            if (!res.error) {
                this.machine = res.machine;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleDelete() {
            const res = await MachineController.deleteMachine(this.machine.uid);
            if (!res.error) {
                this.$notify(this.$t("success.machineDeleted"), { position: "top" });
                this.$router.push("/administration/machines");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }        
    } 
})
